import { authContext } from '../config/adalConfig';
import Store from './store.js';

class Auth {

  constructor(){
    const store = new Store();
    this.state = {
      username: store.getItem("profile").userName,
      display: store.getItem("profile").profile.name,
      login: false,
      password: null,
    }
  }

  isLoggedIn = () =>{
    return(this.login);
  }

  getUsername = () =>{
    var cleanname = this.state.username.substr(0, this.state.username.indexOf('@'));
    return(cleanname)
  }

  getDisplayname = () =>{
    var cleandisplay = this.state.display;
    return(cleandisplay)
  }
  
  logout = () => {
    authContext.logOut()
  }

}

export default Auth;
