import React, { Component } from 'react';
import Authentication from '../../../service/Authentication.js'

class login extends Component {

    Auth = new Authentication();

    constructor(props){
        super(props)
        if (props.login){
            this.state = {
                username: this.Auth.getUsername(),
                navlabel: "Logout",
                navlink: "#",
            };
        }else if (!props.login){
            this.state = {
                navlabel: "Login",
                navlink: "#",
            };
        }
      }

    logout(){
        console.log(this.state.username);
        this.Auth.logout();
    }


    render () {
        return (
            <li style={{float: 'right', paddingRight : '5px'}}><button className='nav' type='button' onClick={() => this.logout()}><span>{this.state.navlabel}</span></button></li>
        );
    }
}
  
  export default login;
  