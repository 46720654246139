import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: 'a8c4de38-c6bb-4ad8-aa65-46bec5462957',
  clientId: 'a298f23f-0d69-41b0-93c8-141d27fc83b0',
  endpoints: {
    api: 'api://a298f23f-0d69-41b0-93c8-141d27fc83b0npm ',
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);