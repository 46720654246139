import React, { Component } from 'react';
import '../../styles/navbar.css';
import Login from './items/login.js'
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Welcome from '../apps/Welcome';

class Navbar extends Component {


    render() {
        return (
            <Router>
                <header>
                    <div id='nav'>
                        <ul>
                            {/* <li><a href='Apps'><span>Apps</span></a>
                                <ul>
                                    <div><li><a href='/uploader'><span>Workspace File Uploader</span></a></li></div>
                                    <div><li><a href='/software'><span>Workspace Software Selector</span></a></li></div>
                                </ul>
                            </li> */}
                            <Login login={true} />
                        </ul>
                    </div>
                </header>
                <main>
                    <Switch>
                        <Route path="/"><Welcome/></Route>
                    </Switch>
                </main>
            </Router>
        );
    }
}

export default Navbar;