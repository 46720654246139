import React, { Component } from 'react';
import './App.css';
import Authentication from "./service/Authentication.js"
import Navbar from './components/navbar/navbar.js'
//import Az_ids from './az_idenity.js'


class App extends Component {

  username = () => {
    const Auth = new Authentication();
    console.log(Auth.getUsername())
  }

  render() {
    return (
      <Navbar />
    );
  }
}

export default App;
