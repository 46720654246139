import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { runWithAdal } from 'react-adal';
import { authContext, adalConfig } from './config/adalConfig';
import Store from './service/store.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const DO_NOT_LOGIN = false;
const store = new Store();


runWithAdal(authContext, () => {
    //console.log(authContext.getCachedUser(adalConfig.clientId));
    store.setItem("profile", authContext.getCachedUser(adalConfig.clientId));
    store.setItem("token", authContext.getCachedToken(adalConfig.clientId));
    require('./App.js');
},DO_NOT_LOGIN);

ReactDOM.render((
    <App />
), document.getElementById('root'));


serviceWorker.unregister();
