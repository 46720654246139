import React, { Component } from 'react';
import Authentication from "../../service/Authentication.js";
// eslint-disable-next-line
import { BrowserRouter as Route, withRouter } from "react-router-dom";
import '../../styles/Welcome.css';
//import DropdownButton from 'react-bootstrap/DropdownButton'
const URL = "https://god2on8uj3.execute-api.us-east-1.amazonaws.com/Prod"

class Welcome extends Component {
    Auth = new Authentication();
    constructor(props) {
        super(props);
        this.state = { 
            title: 'Select Build',
            value: 'basic'
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    username = () => {
        return (this.Auth.getDisplayname())
    }

    handleSubmit(event) {
        var user = this.Auth.getUsername();
        var type = this.state.value;
        if(type.length === 0){
            document.getElementById("notifyerror").style.display = "block";
            return;
        }
        var data = { 'username': user, 'type': type };
        console.log(data);
        var params = {
            'x-api-key': 'UJe6nAQb5k6PvB73baJ6499UkKTtLVph58Ml9lfL',
            'Content-Type': 'application/json'
        };
        fetch(URL, {
            // crossDomain: true,
            method: 'POST',
            mode: 'cors',
            headers: params,
            // credentials: 'include',
            body: JSON.stringify(data)
        }).then((response) => {
            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            return response.json();
        })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                throw error;
            });
        event.preventDefault();
        document.getElementById("notify").style.display = "block";
        document.getElementById("form").style.display = "none";
    }


    render() {
        const NoticeMessage = () => (
            <div>
                <p className="noticeMessage" id="notify">Your demo server is being deployed, you will get an email when it is done.</p>
                <p className="noticeMessage" id="notifyerror">Hit Launch to start a new demo server</p>
            </div>
        )
        return (
            // <Router>
            <div className="welcomePage">
                <center>
                    <h1>Welcome {this.username()}</h1>
                    <h3>Click to Launch a new Demo instance.</h3>
                    <NoticeMessage />
                    <br />
                    <form onSubmit={this.handleSubmit} id="form">
                        <br />
                        <br />
                        <button type="submit" className="btn btn-secondary" id="submit">Launch</button>
                    </form>
                </center>
            </div>
        );
    }
}


export default withRouter(Welcome);
